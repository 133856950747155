import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaWellElement = class extends PolymerElement {
  static get is() {
    return 'jha-well';
  }

  static get template() {
    return html`
    <style>
    :host {
      display: block;
      background-color: var(--jha-well-background-color, var(--jha-color-gray-lighter, #f2f2f2));
      color: var(--jha-text-dark, #191919);
      padding: 16px 24px;
    }
    :host([card]) {
      margin-left: -24px;
      margin-right: -24px;
    }
    :host([danger]) {
      --jha-well-background-color: rgba(244, 67, 54, 0.1);
    }
    @media (max-width: 992px) {
      :host([card]) {
        margin-left: -16px;
        margin-right: -16px;
      }
    }
    </style>
    <slot></slot>
  `;
  }
};

customElements.define(window.JhaWellElement.is, window.JhaWellElement);
export default window.JhaWellElement;
