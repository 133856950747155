import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-floating-group.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/error-message/jha-error-message.js';
import '@banno/platform-ux-shared/components/polymer3/jha/icons/jha-icon-warning.js';
import '@banno/platform-ux-shared/components/polymer3/jha/wells/jha-well.js';
import {calculateDeviceId} from '../../js/device-fingerprint.js';
import BrowserCheckService from '@banno/platform-ux-shared/services/browser-check/browser-check-service.js';
import imgUrl from '../../img/icon-circle-user.svg';
/** @polymer */
class JhaLoginElement extends PolymerElement {
  static get is() {
    return 'jha-login';
  }
  static get properties() {
    return {
      browserCheck: {
        type: Object,
        value: BrowserCheckService.getInfo()
      },
      buttonLoading: {
        type: Boolean,
        value: false
      },
      disableSubmitButton: {
        type: Boolean,
        value: false
      },
      email: String,
      lockedOut: {
        type: Boolean,
        value: false
      },
      password: String,
      responseStatus: {
        type: Number
      },
      showRollingLogout: {
        type: Boolean,
        value: false
      },
      showAbsoluteLogout: {
        type: Boolean,
        value: false
      },
      validEmail: {
        type: String,
        value: false
      },
      userIconUrl: {
        type: String,
        value: () => imgUrl,
      },
    };
  }

  constructor() {
    super();
    this.boundKeyHandler_ = this._keyHandler.bind(this);
    /** @type {!Promise<string>} */
    this.deviceHash;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.boundKeyHandler_);

    /** @type {!Promise<string>} */
    this.deviceHash = calculateDeviceId();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.boundKeyHandler_);
  }

  _checkValidity(event) {
    this.validEmail = event.target.checkValidity();
  }

  _hasValidInput(email, password, disableSubmitButton) {
    if (email && this.validEmail && password && password.length > 0 && !disableSubmitButton) {
      return true;
    }
    return false;
  }

  _computeErrorMessage(responseStatus, lockedOut) {
    const resetStatus = -1;
    const statusOk = 200;
    const status500 = 500;
    if (responseStatus === statusOk || responseStatus === resetStatus) {
      return '';
    } else if (responseStatus >= status500) {
      return 'There was an error communicating with the server.';
    } else if (lockedOut) {
      return 'Your account has been locked. Please contact an administrator to unlock it.';
    }
    return 'Double check your login credentials and try again.';
  }

  _computeErrorDisplay(responseStatus, lockedOut) {
    const resetStatus = -1;
    const statusOk = 200;
    const status500 = 500;
    if (responseStatus === statusOk || responseStatus === resetStatus) {
      return false;
    } else if (responseStatus >= status500) {
      this.buttonLoading = false;
      this.disableSubmitButton = true;
      return true;
    } else if (lockedOut) {
      this.buttonLoading = false;
      this.disableSubmitButton = true;
      return true;
    }
    this.buttonLoading = false;
    this.disableSubmitButton = true;
    return true;
  }

  _keyHandler(e) {
    const ENTER = 13;
    const pressedKey = e.keyCode;
    this.responseStatus = -1;
    this.lockedOut = false;
    this.disableSubmitButton = false;

    if ((pressedKey === ENTER) && this.validEmail) {
      this.login(e);
    }
  }

  /** @param {!Event} evt */
  login(evt) {
    evt.preventDefault();
    if (this.validEmail) {
      this.buttonLoading = true;
      this.deviceHash.then((deviceId) => {
        this.dispatchEvent(new CustomEvent('login', {
          bubbles: true,
          composed: true,
          detail: {
            email: this.email,
            password: this.password,
            deviceId
          }
        }));
      });
    }
  }

  _onBlurUser(event) {
    this.dispatchEvent(new CustomEvent(JhaLoginElement.Event.CHECK_PROVIDER, {
      bubbles: true,
      composed: true,
      detail: {
        email: this.email,
      }
    }));
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      header,
      form,
      footer {
        text-align: center;
      }
      header {
        padding: 16px 16px 0;
        border-bottom: 1px solid var(--jha-border-color);
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 12px 0 24px;
        font-size: 16px;
        font-weight: 600;
      }
      form {
        padding: 16px;
        margin: 0;
      }
      jha-error-message {
        margin-bottom: 24px;
      }
      #login-avatar {
        width: 81px;
        height: 81px;
        margin-bottom: 32px;
      }
      jha-form-floating-group {
        max-width: 300px;
        margin: 0 auto 16px;
      }
      jha-well div:first-of-type {
        color: var(--jha-text-dark);
        font-weight: 600;
        font-size: 15px;
      }
      .inactivity-message {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }
      jha-icon-warning {
        fill: var(--jha-text-base);
        margin-right: 16px;
      }
      a {
        @apply --base-anchor-styles;
      }
      a:hover {
        @apply --base-anchor-hover-styles;
      }
      a:active {
        @apply --base-anchor-active-styles;
      }
      footer {
        width: 100%;
        border-top: 1px solid var(--jha-border-color);
        padding: 16px;
        box-sizing: border-box;
      }
      #loginError {
        color: red;
        font-size: 12px;
        margin-bottom: 16px;
        -webkit-font-smoothing: antialiased;
      }
      jha-link-button {
        --jha-button-text: var(--jha-text-base);
        --jha-button-text-size: 14px;
      }
      @media (min-width: 740px) {
        header {
          padding: 32px 32px 0;
        }
        h2 {
          font-size: 18px;
          font-weight: 400;
          margin-top: 0;
        }
        form,
        footer {
          padding: 32px;
        }
      }
    </style>
    <header>
      <h2>Sign in to your account</h2>
    </header>
    <form on-submit="login">
      <template is="dom-if" if="[[_computeErrorDisplay(responseStatus, lockedOut)]]">
        <div id="loginError">[[_computeErrorMessage(responseStatus, lockedOut)]]</div>
      </template>
      <template is="dom-if" if="[[showRollingLogout]]">
        <jha-well class="inactivity-message">
          <jha-icon-warning></jha-icon-warning>
          You have been signed out due to 30 minutes of inactivity.
        </jha-well>
      </template>
      <template is="dom-if" if="[[showAbsoluteLogout]]">
        <jha-well class="inactivity-message">
          <jha-icon-warning></jha-icon-warning>
          For security reasons, you have been signed out.
        </jha-well>
      </template>
      <img id="login-avatar" src="[[userIconUrl]]" alt="User icon">
      <jha-form-floating-group id="username-group">
        <!-- eslint-disable @banno/ux/no-auto-binding -->
        <input id="email" type="email" value="{{email::input}}" on-input="_checkValidity" spellcheck="false" autocomplete="username" autocorrect="off" autocapitalize="off" autofocus=""
          on-blur="_onBlurUser"
          on-change="_onBlurUser">
        <div class="label">Email</div>
      </jha-form-floating-group>
      <jha-form-floating-group>
        <!-- eslint-disable @banno/ux/no-auto-binding -->
        <input id="password" type="password" value="{{password::input}}" spellcheck="false" autocomplete="current-password" autocorrect="off" autocapitalize="off">
        <div class="label">Password</div>
      </jha-form-floating-group>
      <jha-button type="submit" id="submitButton" is-loading="[[buttonLoading]]" disabled$="[[!_hasValidInput(email, password, disableSubmitButton)]]">
        Sign in
      </jha-button>
    </form>
    <template is="dom-if" if="[[browserCheck.browserOutOfDate]]">
      <jha-well>
        <div>You are using an outdated browser. <a href="/a/browser/index.html">View our browser support policy.</a></div>
      </jha-well>
    </template>
    <footer>
      <jha-link-button link href="/a/login/forgot-password">
        Forgot password?
      </jha-link-button>
    </footer>
  `;
  }
}
customElements.define(JhaLoginElement.is, JhaLoginElement);

JhaLoginElement.Event = {
  CHECK_PROVIDER: 'check-provider',
};

export default JhaLoginElement;
