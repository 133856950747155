import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import './jha-login.js';
import '../auth/jha-auth.js';
import './jha-xp-login.js';
import JhaFetch from '@banno/platform-ux-shared/services/jha-fetch.js';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import UserAccessController from '@banno/platform-ux-shared/controllers/user-access-controller.js';
import TwoFactorController from '@banno/platform-ux-shared/controllers/two-factor-controller.js';
import RoutePaths from '../../js/routing/paths.js';
import AuthController from '@banno/platform-ux-shared/controllers/auth-controller.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
import router from '@banno/platform-ux-shared/services/router-jh-wcr.js';
import ApiPaths from '@banno/platform-ux-shared/services/api-paths.js';

/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const LoginContainerBase = RouterMixin(PolymerElement);
/** @polymer */
class LoginContainerElement extends LoginContainerBase {
  static get is() {
    return 'login-container';
  }
  static get properties() {
    return {
      email: String,
      loginLockedOut: {
        type: Boolean,
        value: false
      },
      errorBody: String,
      loggedOutDueToRollingTimeout: {
        type: Boolean,
        value: false
      },
      loggedOutDueToAbsoluteTimeout: {
        type: Boolean,
        value: false
      },
      institutions: Array,
      loginResponseStatus: {
        type: Number,
        value: 200
      },
      openView: {
        type: String,
        value: 'login'
      },
      redirectPath: {
        type: String,
        value: '',
      },
      user: {
        type: Object,
      },
    };
  }

  ready() {
    super.ready();

    // Detect whether the site was launched from within XPerience.
    if (Boolean(window.localStorage.getItem(`isXp3`))) {
      router.go(RoutePaths.LOGIN_XPERIENCE);
    }
    if (typeof CefSharp !== 'undefined' && CefSharp.BindObjectAsync) {
      this.openView = 'xplogin';
    }
  }

  async routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
    // eslint-disable-next-line camelcase
    const redirectUri = this._verifyRedirect(context.query.get('redirect_to'));
    if (JhaFetch.getCookie('XSRF-TOKEN')) {
      let redirectTo = '';
      if (redirectUri) { // eslint-disable-line camelcase
        redirectTo = `?redirect_to=${encodeURIComponent(redirectUri)}`; // eslint-disable-line camelcase
      }
      location.href = `${RoutePaths.LOGIN_LOGOUT}${redirectTo}`;
      return next(false);
    }
    if (context.query.get('rolling')) {
      this.loggedOutDueToRollingTimeout = true;
    }
    if (context.query.get('absolute')) {
      this.loggedOutDueToAbsoluteTimeout = true;
    }
    await super.routeEnter(currentNode, nextNodeIfExists, routeId, context, next);
    if (redirectUri) { // eslint-disable-line camelcase
      this.redirectPath = redirectUri; // eslint-disable-line camelcase
    }
  }

  _authVerified() {
    this._storeSessionDetails();
    this._redirect();
  }

  _close(e) {
    this._redirect();
  }

  _getSelectedInstitutionId() {
    if (!this.institutions) {
      return '';
    }
    if (this.institutions.length === 1) {
      this._setSelectedInstitutionInStore(this.institutions[0]);
      return this.institutions[0].institution.shortId.toString();
    }
    const selectedInstitution = this.institutions.find((instData) => instData.selected);
    if (selectedInstitution) {
      return selectedInstitution.institution.shortId.toString();
    }
  }

  async _setSelectedInstitutionInStore(selectedInstitution) {
    const {shortId} = selectedInstitution.institution;
    store.selectedInstitution = shortId;
  }

  async _redirect(isXperience = false) {
    const selectedInstitutionShortId = this._getSelectedInstitutionId();
    if (await this._profileIncomplete()) {
      const profileURL = '/a/settings/profile';
      router.go(profileURL + (this.redirectPath ? '?redirect_to=' + this.redirectPath : ''));
    } else {
      const redirectTo = this.redirectPath ? this.redirectPath.trim() : '/a/';
      if (redirectTo === '/a/') {
        if (isXperience && this.institutions.length > 1) {
          router.go(RoutePaths.INSTITUTIONS);
          return;
        }
        if (selectedInstitutionShortId) {
          router.go(`/a/${selectedInstitutionShortId}`);
          return;
        }
        router.go(RoutePaths.INSTITUTIONS);
      } else {
        router.go(redirectTo);
      }
    }
  }

  _verifyRedirect(redirectTo) {
    if (!redirectTo) {
      return '';
    }

    const redirectURL = new URL(redirectTo, window.location.href);
    const bannoAppURL = new URL(window.location.href);

    const sameHost = redirectURL.host === bannoAppURL.host;
    const validProtocol = redirectURL.protocol === 'https:';
    const validHosts = ['staging.banno.com', 'uat.banno.com', 'banno.com', 'platform.banno-staging.com', 'platform.banno-development.com', 'platform.banno.com', 'localhost'];
    const whitelisted = validHosts.includes(redirectURL.hostname);

    return sameHost && validProtocol && whitelisted ? redirectTo : '';
  }

  _changeView(view) {
    this.openView = view;
  }

  _isOpen(view) {
    return view === this.openView;
  }

  _xplogin(event) {
    const {SAMLToken, deviceId, firstName, lastName} = event.detail;
    this.loginResponseStatus = -1;
    AuthController.xplogin(SAMLToken, deviceId)
        .then((user) => this._xploginSuccess(user, firstName, lastName))
        .catch((error) => this._loginError(error));
  }

  // TODO: Update user profile with SAML Token values
  async _xploginSuccess(user, firstName, lastName) {
    return this._logicSuccess(user, true);
  }

  _login(event) {
    this.email = event.detail.email;
    const {password} = event.detail;
    const {deviceId} = event.detail;
    this.loginResponseStatus = -1;
    AuthController.login(this.email, password, deviceId)
        .then((user) => this._logicSuccess(user))
        .catch((error) => this._loginError(error));
  }

  _onCheckProvider(event) {
    this.email = event.detail.email;

    if (!this.email) {
      return;
    }

    AuthController.getJhidProvider(this.email)
        .then((config) => {
          switch (config.providerType) {
            case 'jhid_ad':
            case 'jhid_google': {
              const bffPath = window.location.hostname === 'localhost' ?
                ApiPaths.BFF_PATH_LOCALHOST :
                ApiPaths.BFF_PATH;
              const currentUrl = new URL(window.location.href);
              const redirectParam = currentUrl.searchParams.get('redirect_to');
              const redirectUri = new URL(`${RoutePaths.LOGIN_COMPLETE}${redirectParam ? `?redirect_to=${redirectParam}` : ''}`, window.location.origin);
              if (/localhost/.test(location.host)) {
                window.location.href = `${bffPath}/bff/login?redirect_uri=${encodeURIComponent(redirectUri.toString())}&login_hint=${this.email}`;
                return;
              } else if (/development|staging/.test(location.host)) {
                window.location.href = `${bffPath}/bff/login?redirect_uri=https://${location.host}${RoutePaths.LOGIN_COMPLETE}&login_hint=${this.email}`;
                return;
              }
              // fall through to sentry login for production
            }
            case 'sentry':
            default:
              return;
          }
        })
        .catch((error) => {});
  }

  async _logicSuccess(user, isXperience = false) {
    const session = await AuthController.getSession();
    const highRisk = session.highRisk;
    this.institutions = session.institutions;
    this.user = session.user;
    this.highRiskEnrolled = highRisk.enrolled;
    this.highRiskRequired = highRisk.required;
    if (highRisk.enrolled && !highRisk.verified) {
      TwoFactorController.getAuthSession({userId: this.user.userId}).then((session) => {
        this.highRiskEnrolled = session.enrollment.confirmed;
        if (this.highRiskEnrolled && !session.verified) {
          this.userPhone = session.enrollment.phone;
          this._changeView('auth');
        } else if (this.highRiskEnrolled && session.verified) {
          this._storeSessionDetails();
          this._redirect(isXperience);
        }
      });
    } else if (highRisk.enabled && !highRisk.verified) {
      this._changeView('auth');
    } else {
      this._storeSessionDetails();
      this._redirect(isXperience);
    }
  }

  _loginError(error) {
    console.log(error);
    if (!error) {
      return;
    }
    this.loginResponseStatus = error.statusCode;
    if (error) {
      this.errorBody = error.response;
    }
    if (error.response && error.response.lockedOut) {
      this.loginLockedOut = true;
    }
  }

  _storeSessionDetails() {
    store.institutions = this.institutions;
    store.user = this.user;
  }

  async _profileIncomplete() {
    if (!this.user.internal) {
      if (!this.user.firstName || !this.user.lastName) {
        return true;
      } else if (!this.user.bio || !this.user.location) {
        const mailboxesKey = this.institutions.map((institution) => ({
          institutionId: institution.institution.institutionId,
          privilege: 'read',
          application: 'mailboxes',
          resource: 'conversations',
          resourceId: '*',
          property: '*',
          key: '1'
        }));
        const mailboxPermission = await UserAccessController.validatePermissions(mailboxesKey);
        return mailboxPermission.some((permission) => permission.result === 'allowed');
      }
      return false;
    }
    return false;
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        width: 90%;
        min-height: 0;
        max-height: none;
        max-width: 550px;
        border-radius: 2px;
        box-shadow: 0 3px 12px 0 rgba(37,49,62,.24),0 3px 24px rgba(37,49,62,.12);
        border: none;
        margin: auto;
        padding: 0;
        background: white;
      }
      @media (max-width: 480px) {
        :host {
          border-radius: 0;
          width: 100%;
          height: 100%;
        }
      }
    </style>
    <template is="dom-if" if="[[_isOpen('login', openView)]]">
      <jha-login
        show-rolling-logout="[[loggedOutDueToRollingTimeout]]"
        show-absolute-logout="[[loggedOutDueToAbsoluteTimeout]]"
        response-status="[[loginResponseStatus]]"
        locked-out="[[loginLockedOut]]"
        on-login="_login"
        on-check-provider="_onCheckProvider">
      </jha-login>
    </template>
    <template is="dom-if" if="[[_isOpen('xplogin', openView)]]">
      <jha-xp-login response-status="[[loginResponseStatus]]" error-body="[[errorBody]]" on-login="_xplogin">
      </jha-xp-login>
    </template>
    <template is="dom-if" if="[[_isOpen('auth', openView)]]">
      <jha-auth on-close="_close" user-id="[[user.userId]]" email="[[email]]" is-enrolled="[[highRiskEnrolled]]" on-auth-verified="_authVerified" phone="[[userPhone]]" auth-required="[[highRiskRequired]]" is-open="[[_isOpen('auth', openView)]]"></jha-auth>
    </template>
  `;
  }
}
customElements.define(LoginContainerElement.is, LoginContainerElement);
export default LoginContainerElement;
