import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaErrorMessageElement = class extends PolymerElement {
  static get is() {
    return 'jha-error-message';
  }

  static get template() {
    return html`
    <style>
      :host {
        color: var(--jha-error-message-text-color, var(--jha-text-white));
        background-color: var(--jha-error-message-background-color, var(--jha-color-warning));
        border-radius: 3px;
        @apply --flex-space-between;
        padding: 16px 20px;
        text-align: left;
      }
      div {
        flex-grow: 1;
      }
      @media (max-width: 480px) {
        :host {
          padding: 10px;
        }
      }
    </style>

    <div role="alert"><slot></slot></div>

  `;
  }
};

customElements.define(window.JhaErrorMessageElement.is, window.JhaErrorMessageElement);
export default window.JhaErrorMessageElement;
