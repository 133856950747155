import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/progress/jha-progress.js';
import '@banno/platform-ux-shared/components/polymer3/jha/forms/jha-form-floating-group.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/buttons/jha-link-button.js';
import '@banno/platform-ux-shared/components/polymer3/jha/wells/jha-well.js';
import {calculateDeviceId} from '../../js/device-fingerprint.js';

/** @polymer */
class JhaXpLoginElement extends PolymerElement {
  static get is() {
    return 'jha-xp-login';
  }
  static get properties() {
    return {
      errorBody: {
        type: String,
        observer: JhaXpLoginElement.prototype._endLoading
      },
      responseStatus: {
        type: Number,
        observer: JhaXpLoginElement.prototype._endLoading
      },
      username: String,
      loading: {
        type: Boolean,
        value: false
      }
    };
  }

  constructor() {
    super();
    /** @type {!Promise<string>} */
    this.deviceHash;
  }

  connectedCallback() {
    super.connectedCallback();

    this.loading = true;

    /** @type {!Promise<string>} */
    this.deviceHash = calculateDeviceId();

    CefSharp.BindObjectAsync('JHAXPBridge')
        .then(() => JHAXPBridge.getCurrentUserSamlToken())
        .then((SAMLToken) => this.login(SAMLToken));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.boundKeyHandler_);
  }

  _endLoading() {
    if (this.responseStatus !== undefined && (this.errorBody || this.responseStatus >= 400)) {
      this.loading = false;
    }
  }

  /**
   * @param {number} responseStatus
   * @param {string} errorBody
   * @return {string}
   */
  _computeErrorHeading(responseStatus, errorBody) {
    return this._computeError(responseStatus, errorBody).heading;
  }

  /**
   * @param {number} responseStatus
   * @param {string} errorBody
   * @return {string}
   */
  _computeErrorMessage(responseStatus, errorBody) {
    return this._computeError(responseStatus, errorBody).message;
  }

  /**
   * @param {number} responseStatus
   * @param {string} errorBody
   * @return {{heading: string, message: string}}
   */
  _computeError(responseStatus, errorBody) {
    if (responseStatus === undefined || responseStatus <= 300) {
      return {heading: '', message: ''};
    }

    const heading = 'Oops, something went wrong';
    const message =  'We were unable to authenticate your login. Please close the tab and try again.';
    if (responseStatus >= 500) {
      return {
        heading,
        message
      };
    }

    switch ((errorBody || '').trim()) {
      case 'EnterpriseUserNotFound':
        return {
          heading: 'Request access from your administrator',
          message: `You've logged in successfully, however you need additional permissions to have access. ` +
              `Please contact your administrator and request updated permissions. Once updated, try again.`
        };
      case 'NoXperienceEnabledInstitutions':
        return {
          heading: 'Xperience logins unavailable',
          message: 'Your institution is not yet configured to support logins from Xperience. ' +
              'Please have your administrator contact Banno to configure your institution.'
        };
      case 'EmailMissingFailure':
        return {
          heading: 'Xperience emailaddress attribute is missing',
          message: 'Your email address is not configured in Xperience. Missing the the emailaddress attribute. ' +
              'Please contact your I.T. Administrator and request for them to set the emailaddress attribute on your Active Directory or LDAP account. Once updated, please try again.'
        };

      default:
        break;
    }

    return {
      heading,
      message
    };
  }

  /**
   * @param {!Document} samlDoc
   * @param {string} claimId
   * @return {?string}
   */
  _getSAMLClaim(samlDoc, claimId) {
    const elem = samlDoc.querySelector(`attribute[name="${claimId}"] > attributeValue`);
    if (elem) {
      return elem.innerText;
    }
    return null;
  }

  /** @param {string} SAMLToken */
  login(SAMLToken) {
    /** @type {?string} */
    let firstName = null;
    /** @type {?string} */
    let lastName = null;
    /** @type {?string} */
    let email = null;

    try {
      const domParser = new DOMParser();
      const samlDoc = domParser.parseFromString(SAMLToken, 'text/html');
      firstName = this._getSAMLClaim(samlDoc, 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname');
      lastName = this._getSAMLClaim(samlDoc, 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname');
      email = this._getSAMLClaim(samlDoc, 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress');
    } catch (e) {}

    this.deviceHash.then((deviceId) => {
      this.dispatchEvent(new CustomEvent('login', {
        bubbles: true,
        composed: true,
        detail: {
          SAMLToken,
          deviceId,
          firstName,
          lastName,
          email
        }
      }));
    });
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
      }
      header {
        text-align: center;
        padding: 32px;
        border-bottom: 1px solid var(--jha-border-color);
      }
      h2 {
        color: var(--jha-text-dark);
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
      article {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
      }
      jha-progress {
        margin: 32px auto;
        display: block;
        width: 32px;
        height: 32px;
      }
      @media (min-width: 740px) {
        h2 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    </style>
    <template is="dom-if" if="[[!loading]]">
      <header>
        <h2>[[ _computeErrorHeading(responseStatus, errorBody)]]</h2>
      </header>
      <article>
        [[_computeErrorMessage(responseStatus, errorBody)]]
      </article>
    </template>
    <template is="dom-if" if="[[loading]]">
      <article>
        <jha-progress></jha-progress>
      </article>
    </template>
  `;
  }
}
customElements.define(JhaXpLoginElement.is, JhaXpLoginElement);
export default JhaXpLoginElement;
